exports.components = {
  "component---src-components-course-about-tsx": () => import("./../../../src/components/CourseAbout.tsx" /* webpackChunkName: "component---src-components-course-about-tsx" */),
  "component---src-components-course-tsx": () => import("./../../../src/components/Course.tsx" /* webpackChunkName: "component---src-components-course-tsx" */),
  "component---src-components-team-about-temelate-tsx": () => import("./../../../src/components/TeamAboutTemelate.tsx" /* webpackChunkName: "component---src-components-team-about-temelate-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sessions-tsx": () => import("./../../../src/pages/sessions.tsx" /* webpackChunkName: "component---src-pages-sessions-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

